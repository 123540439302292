import React from "react"
import Layout from "@components/layout"
import Seo from "@components/seo"
import SolutionsSidebar from "@components/solutionsSidebar"
import { graphql } from "gatsby"
const SolutionsPage = ({ data }) => (
  <Layout>
    <Seo title="Solutions" description={data.site.siteMetadata.description} />
    <div className="page-headline">
      <div className="container">
        <h1>Solutions</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-7 col-lg-8">
            <h2 className="text-primary">It's what we do.</h2>
            <p>
              By partnering with a company who provides End-To-End solutions in
              IT, your focus will remain on your corporate strategies. Imagine
              having the resources of a comprehensive IT department at your
              disposal without the overhead, turnover and training of managing
              an internal IT staff.
            </p>
            <p>
              We provide a complete suite of IT Solutions to manage the
              technology related needs of your business. From voice & data
              circuits, cloud solutions to internal tech support. We have IT
              covered.
            </p>
            <p>
              Consider where you will use the time and energy you save by
              partnering with a company that actually manages your IT.
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <SolutionsSidebar />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SolutionsPage

export const SolutionsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
